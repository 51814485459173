import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageItem from "../components/pageItem"
import SEO from "../components/seo"
import Img from "gatsby-image"

const SecondPage = (props) => (
  <Layout pageInfo={{ pageName: "Aether" }}>
    <SEO title="Aether" />
    {/* <h1>Ather</h1> */}
    {/* <p>Welcome to page 2</p> */}
    {/* <Link to="/">Go back to the homepage</Link> */}
    <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
      {/* You still need to add the hidden input with the form name to your JSX form */}
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label>Your Name_: <input type="text" name="name" /></label>
      </p>
      <p>
        <label>Your Email: <input type="email" name="email" /></label>
      </p>
      {/* <p>
    <label>Your Role: <select name="role[]" multiple>
      <option value="leader">Leader</option>
      <option value="follower">Follower</option>
    </select></label>
  </p> */}
      <p>
        <label>Message: <textarea name="message"></textarea></label>
      </p>
      <p>
        <button type="submit">Send</button>
      </p>
    </form>

  </Layout>
)

export default SecondPage

// export const fluidImage = graphql`
// fragment fluidImage on File {
//   childImageSharp {
//     fluid(maxWidth: 1000) {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }
// `;

// export const pageQuery = graphql`
//   query {
//     aether01: file(relativePath: { eq: "aether_01.jpg" }) {
//       ...fluidImage
//     }
//   }
// `